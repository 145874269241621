import { Box, Drawer, List, ListItemButton, ListItemText } from "@mui/material"
import { Link } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';
import { handleRenderLink } from "./HandleLinks";
import { useTranslation } from "react-i18next";
export const AsideMobile = ({ openDrawer, handleCloseDrawer, user }) => {
    const { t } = useTranslation("header");
    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleCloseDrawer}
            aria-hidden="false"
            sx={{
                backgroundColor: "primary",
                "& .MuiPaper-root ": {
                    borderRadius: "50px 0px 0px 50px",
                    "& .MuiBox-root": {
                        backgroundColor: "text.main"

                    }
                }
            }}
        >
            <Box
                sx={{
                    width: "300px",
                    maxWidth: "300px",
                    bgcolor: "background.paper",
                    height: "100%",
                    py: 5,
                }}
            >
                <CloseIcon sx={{ color: "secondary.main", fontSize: "35px", marginLeft: "20px", cursor: "pointer" }} onClick={handleCloseDrawer} />
                <List>

                    {
                        handleRenderLink(user).map((link, index) => (
                            link.renderd ? (
                                <ListItemButton
                                    key={index}
                                    component={Link}
                                    to={link.to}
                                    onClick={handleCloseDrawer}
                                    sx={{
                                        transition: 'transform 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    <ListItemText primary={t(link.name)} sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                                </ListItemButton>
                            ) : null
                        ))
                    }

                </List>
            </Box>
        </Drawer>
    )
}
