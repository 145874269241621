import { Box, Button, Typography } from "@mui/material";
import uploadImg from "../../../assets/images/uploadImg.webp"
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import { useRef } from "react";
export const RenderMainImageInput = ({ handleImageChange, setFieldValue, values, imageLoading, handleDeleteMainImage }) => {
    const { t } = useTranslation("add-product");
    const fileInputRef = useRef(null);

    // Handle delete and reset the file input
    const handleDelete = () => {
        handleDeleteMainImage();
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };
    return (
        <Box>
            <input
                ref={fileInputRef}
                type="file"
                id="mainImage"
                onChange={(event) => handleImageChange(event, 0, setFieldValue)}

                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                    cursor: 'pointer',
                }}
            />
            {values.pictures[0] ? null : (
                <label htmlFor="mainImage">
                    {imageLoading[0] ? (
                        <Box sx={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t("loading")}
                        </Box>
                    ) : (
                        <>
                            <Box >
                                <img src={uploadImg} alt="upload" style={{ width: "100%", height: "50px", objectFit: "contain", }} loading='lazy' />
                            </Box>
                            <Typography variant="body1" gutterBottom component="div" sx={{ fontSize: ["8px", "16px"], mt: 3 }}>
                                {t("dragDrop")}
                            </Typography>
                        </>
                    )}
                </label>
            )}
            {values.pictures[0] && (
                <Box sx={{ width: "100%" }}>
                    <img src={values.pictures[0]} alt="main" style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }} />
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        sx={{ position: "absolute", top: "5px", right: "5px", width: "30px", height: "30px", justifyContent: "center", alignItems: "center" }}
                    >
                        <DeleteIcon />
                    </Button>
                </Box>
            )}
        </Box>
    )
}