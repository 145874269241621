import { useEffect, useState } from "react"
import axiosInstance from "../../../axios/axiosInstance"
import { Grid, Stack } from "@mui/material"
import { SkeletonLoadingProducts } from "../../general/loading/SkeletonLoadingProducts"
import { ProductCard } from "./ProductCard"
import { useTranslation } from "react-i18next"
export const PastRents = () => {
    const { t } = useTranslation("profile")
    const [pastRent, setPastRent] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const getPastRents = async () => {
            setLoading(true)
            // get past rents
            const pastRents = await axiosInstance.get(`/transactions/user/past-rents`)
            setPastRent(pastRents.data.pastTransactions)
            setLoading(false)
        }
        getPastRents()
    }, [])

    return (
        <Grid container spacing={2}>
            {

                loading ? ["", "", "", "", "", ""].map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} >
                        <SkeletonLoadingProducts />
                    </Grid>
                )) : pastRent.length > 0 ? (
                    pastRent.map((rent) => (
                        <Grid item key={rent?._id} xs={12} sm={12} md={6}>
                            <ProductCard transaction={rent} />
                        </Grid>
                    ))
                ) : (
                    <Stack direction="row" sx={{ justifyContent: "center", color: "primary.main", width: "100%" }} >
                        {t("not-found")}
                    </Stack>
                )
            }
        </Grid>
    )
}
