import { Box, Checkbox, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUser, getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";
export const NotifyMe = () => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("product-details");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const authUser = useSelector(getUser); // get user from redux
  const { productID } = useParams();
  // fetch unavailable dates from the server and determine if product is available
  useEffect(() => {
    axiosInstance
      .get(`/products/${productID}/unavailable-dates`)
      .then((res) => {
        const currentDate = dayjs(); // get current date
        const unavailableDates = res.data.unavailableDates; // get unavailable dates from the server
        let isProductAvailable = true; // assume product is available initially

        if (currentDate.isValid()) {
          for (let i = 0; i < unavailableDates.length; i++) {
            const startDate = dayjs(unavailableDates[i].start_date);
            const endDate = dayjs(unavailableDates[i].end_date);

            // check if current date is within the unavailable date range
            if (
              (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) ||
              currentDate.isSame(startDate) ||
              currentDate.isSame(endDate)
            ) {
              isProductAvailable = false; // product is unavailable if current date is within the unavailable date range
              break; // no need to check further
            }
          }
        }

        setShowNotifyMe(isProductAvailable); // set showNotifyMe based on product availability
      })

  }, [productID,]);

  useEffect(() => {
    if (authUser) {
      // check if product is in user's watchlist
      const isProductInWatchlist = authUser?.user?.watchlist?.includes(productID);
      setChecked(isProductInWatchlist);  // set checked state based on whether product is in user's watchlist
    }

  }, [authUser, productID]);

  const handleNotifyMe = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      axiosInstance
        .post(
          `/user/add-to-watchlist/${productID}`,
          {},
        )
        .then(() => {
          showAlert(
            "success",
            "We will send notification to you when item become available"
          );

          // fetch user details after adding product to watchlist
          dispatch(fetchUser());

        })
        .catch((err) => {
          showAlert("error", err.response.data.error);

        });
    } else {
      axiosInstance.delete(
        `/user/remove-from-watchlist/${authUser?.id
        }/${productID}`
      ).then(() => {
        // fetch user details after delete product to watchlist
        dispatch(fetchUser());
      })
    }
  };
  return (
    <>
      {!showNotifyMe ? (
        <Stack direction="row" sx={{ my: 1, alignItems: "center", backgroundColor: "white", borderRadius: "10px" }}>
          <Box>
            <Checkbox checked={!!checked} onChange={handleNotifyMe} />
          </Box>
          <Box>
            <Typography
              variant="body1"
              component="span"
              sx={{ py: 4, lineHeight: "30px", color: "primary.main" }}
            >
              {t("notifyMe")}
            </Typography>
          </Box>
        </Stack>
      ) : null}
    </>
  );
};
