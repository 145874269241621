import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, Stack } from '@mui/material'
import { useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';
import { useTranslation } from 'react-i18next';
import { useShowAlert } from '../../hooks/useShowAlert';
export const PaymentForm = ({ transactionID, status }) => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("payment")
    const [success, setSuccess] = useState(false)// to check if the payment is successful or not in case of cash on delivery
    const [loading, setLoading] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState('Credit Card');
    // handle payment method change
    const handleChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    // sunmit payment method to the server
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        // validate the payment method before sending it to the server
        if (paymentMethod === "") {
            showAlert("error", "Please select a payment method")

        }

        if (paymentMethod === "Cash On Delivery") {
            axiosInstance.post("payments/create", { transactionID: transactionID }).then(() => {
                showAlert("success", "Transaction created successfully")
                setSuccess(true)
            }).catch((error) => {
                showAlert("error", error.response.data.error)
            }).finally(() => {
                setLoading(false)
            })

        } else {
            axiosInstance.post("/payments/stripe-payment", { transactionId: transactionID }).then((response) => {
                window.location.href =
                    `${response.data.checkoutURL}`;
            }).catch((error) => {
                showAlert("error", error.response.data.error)
            }).finally(() => {
                setLoading(false)
            })
        }


    }
    return (
        <Box sx={{ py: 2 }}>
            <form>
                <Stack spacing={2}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("paymentMethod.title")}</FormLabel>
                        <RadioGroup row aria-label="paymentMethod" name="row-radio-buttons-group" value={paymentMethod} onChange={handleChange}>
                            <FormControlLabel value="Credit Card" control={<Radio />} label={t("paymentMethod.creditCard")} />
                            <FormControlLabel value="Cash On Delivery" control={<Radio />} label={t("paymentMethod.cash")} />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ px: 8 }}>
                        <Button fullWidth variant="contained" color="secondary" type="submit" onClick={handleSubmit} disabled={status === "Ongoing" || success || loading}>
                            {t('pay')}
                        </Button>
                    </Box>
                </Stack>
            </form>

        </Box>
    )
}