import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik, Form, Field } from "formik";
import { Button, Typography, FormControl, TextField, Box, Stack, } from "@mui/material";
import { useMemo, useState, } from "react";
import dayjs from "dayjs";
import { CustomDatePicker } from "./helper/CustomDatePicker";
import { NotifyMe } from "../NotifyMe";
import { useSubmit } from "./helper/UseSubmit";
import { calculateEndDate } from "./helper/helper_fun";
import { useTranslation } from "react-i18next";
export const RentDate = ({ product }) => {
  console.log(product.unavailability);
  const { t } = useTranslation("product-details");
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  const initialValues = {
    From: null,
    To: null,
    number: "",
  };

  const { onSubmit, GoChatPage, loading } = useSubmit(product);

  // memoize the disabledDates computation
  const disabledDates = useMemo(
    () =>
      product?.unavailability?.map(({ start_date, end_date }) => ({
        start: dayjs(start_date),
        end: dayjs(end_date),
      })),
    [product?.unavailability]
  );

  const isDateDisabled = (date) => {
    const selectedDate = dayjs(date);
    return disabledDates.some(
      ({ start, end }) =>
        selectedDate.isBetween(
          start.startOf("day"),
          end.endOf("day"),
          { inclusive: "[]" }
        ) || selectedDate.isSame(start.startOf("day"))
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => {

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Form>
              <Box sx={{ width: "100%", backgroundColor: "#F7F7F7", p: 2, borderRadius: "5px" }}>
                <Typography variant="h6" component="h2" color="textPrimary" mb={2}>
                  {t("startRent")}
                </Typography>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <Field
                      fullWidth
                      name="From"
                      as={CustomDatePicker}
                      value={dayjs(values.From || "")}
                      minDate={dayjs(today)}
                      shouldDisableDate={isDateDisabled}
                      disabled={!product?.available}
                      onChange={(newDate) => {
                        setFieldValue("From", dayjs(newDate))
                        if (values.number > 0) {
                          const calculatedEndDate = calculateEndDate(newDate, values.number, product.per);
                          setEndDate(calculatedEndDate);
                          setFieldValue("To", calculatedEndDate);
                        }
                      }}
                    />
                  </FormControl>
                  {product?.per === "Day" ? (
                    <FormControl fullWidth>
                      <Field
                        fullWidth
                        name="To"
                        as={CustomDatePicker}
                        value={dayjs(values.To || "")}
                        minDate={values.From ? dayjs(values.From).add(1, 'day') : dayjs(today)}
                        shouldDisableDate={isDateDisabled}
                        disabled={!product?.available}
                        onChange={(newDate) => setFieldValue("To", dayjs(newDate))}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        name="number"
                        type="number"
                        label={`Number of ${product?.per}`}
                        value={values.number || ""}
                        disabled={!product?.available}
                        onChange={(event) => {
                          const numberValue = event.target.value;
                          setFieldValue("number", numberValue);
                          // Calculate end date as number changes
                          if (values.From && numberValue > 0) {
                            const calculatedEndDate = calculateEndDate(values.From, numberValue, product.per);
                            setEndDate(calculatedEndDate);
                            setFieldValue("To", calculatedEndDate);
                          }
                        }}
                      />
                    </FormControl>
                  )}
                  {endDate && (
                    <Typography variant="body2" color="textSecondary">
                      {t("endDate")} {dayjs(endDate).format("ddd, MMM DD, YYYY")}
                    </Typography>
                  )}
                  <NotifyMe />
                  {!product?.available && (
                    <Typography variant="caption" component="p" color="error">
                      {t("notAvailable")}
                    </Typography>
                  )}
                </Stack>
              </Box>
              <Stack spacing={2} sx={{ my: 3 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ backgroundColor: "#F7F7F7", color: "secondary.main", "&:hover": { backgroundColor: "#F7F7F7", color: "secondary.main" } }}
                  onClick={() => GoChatPage(product?.userID)}
                >
                  {t("chat")}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={!product?.available || loading}
                >
                  {t("rentBtn")}
                </Button>
              </Stack>
            </Form>
          </LocalizationProvider>
        );
      }}
    </Formik>
  );
};
